import React from 'react';
import { Icon } from '../../../../components/Icons';
import styles from './style.module.css';

interface Props {
  iconName: string;
  title: string;
}

export const Header = ({ iconName, title }: Props) => (
  <header className="mv32 mv48-m mv48-l">
    <h1 className="flex fw7 f20 f30-m f30-l lh-title abbey">
      <span className="mr8 red">
        <Icon className={styles.icon} name={iconName} />
      </span>
      <span className={styles.title}>{title}</span>
    </h1>
  </header>
);
