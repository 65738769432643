import React from 'react';
import { Layout } from '../components/Layout';
// import { Menu } from '../modules/Menu';
import { Faq } from '../modules/Faq';
import { Footer } from '../modules/Footer';

const FaqPage = () => (
  <Layout>
    {/* FIXME: Responsivo do menu altera altura no brk 1024 para 72 */}
    {/* QA recomendou registrar em task da página este comportamento do menu e a necessidade da impl */}
    {/* <Menu menuHasTwoColors={false} /> */}
    <Faq />
    <Footer />
  </Layout>
);

export default FaqPage;
