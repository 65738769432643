import React from 'react';
import classNames from 'classnames';
import _map from 'lodash/map';
import { NarrowContainer } from '../../../../components/Container/NarrowContainer';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { HyperlinkIcon } from '../../types';
import { ImageBoxLink } from '../ImageBoxLink';
import styles from './style.module.css';

interface Props {
  dataSource: HyperlinkIcon[];
}

export const Topics = ({ dataSource }: Props) => {
  const gridClasses = classNames('ma0', 'pa0', 'list', 'gga12', styles.list);

  const renderItem = (hyperlink: HyperlinkIcon) => (
    <GridCell
      key={`Topics-${hyperlink.text}`}
      width={[1 / 2, 3 / 12]}
      className="pa6 pv0-ns ph12-m ph12-l"
    >
      <ImageBoxLink hyperlink={hyperlink} />
    </GridCell>
  );

  return (
    <div className="bg-wild-sand pv32 pv48-m pv48-l">
      <NarrowContainer>
        <h2 className="f24 ma0 mb16 fw2 abbey">Tópicos</h2>

        <Grid className={gridClasses} alignItems="center">
          {_map(dataSource, renderItem)}
        </Grid>
      </NarrowContainer>
    </div>
  );
};
