import React from 'react';
import MediaQuery from 'react-responsive';
import { Hyperlink } from '../../types';
import { ExpandableLinkList } from '../ExpandableLinkList';
import { MultiColumnList } from '../MultiColumnList';

interface Props {
  dataSource: Hyperlink[];
}

export const CategorizedLinkList = ({ dataSource }: Props) => {
  return (
    <>
      <h1 className="ma0 fw2 f20 f24-m f24-l mb16 abbey">
        Dúvidas <span className="fw7">em destaque</span>
      </h1>

      <MediaQuery maxWidth={1023}>
        <ExpandableLinkList dataSource={dataSource} />
      </MediaQuery>

      <MediaQuery minWidth={1024}>
        <MultiColumnList dataSource={dataSource} columns={2} layout={[1, 1, 5 / 12, 4 / 12]}>
          {(data: any[]) => <ExpandableLinkList dataSource={data} />}
        </MultiColumnList>
      </MediaQuery>
    </>
  );
};
