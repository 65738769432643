import React from 'react';
import _map from 'lodash/map';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';

interface Props {
  dataSource: any[];
  columns: number;
  layout: number[];
  children: (column: any[]) => any;
}

export const MultiColumnList = ({ dataSource, columns = 1, layout = [1], children }: Props) => {
  const matrix = createMatrix(dataSource, columns);

  const renderColumn = (column: any[]) => (
    <GridCell className="pa6 pv0-ns ph12-m ph12-l" width={layout}>
      {children(column)}
    </GridCell>
  );

  return <Grid className="ma0 pa0 gga12">{_map(matrix, renderColumn)}</Grid>;
};

function createMatrix(data: any[], columns: number) {
  let remaining = Math.ceil(data.length % columns);
  const rows = Math.floor(data.length / columns);
  // eslint-disable-next-line prefer-spread
  const matrix = Array.apply(null, Array(columns)).map((): any[] => []);

  for (let i = 0, row = 0, column = 0, l = data.length; i < l; i++, row++) {
    if (!hasNextRow(row, rows)) {
      row = -1;
      remaining -= 1;
    }

    if (isNewColumn(i, row, remaining)) {
      column += 1;
    }

    matrix[column].push(data[i]);
  }

  return matrix;
}

function hasNextRow(row: number, rows: number) {
  return row < rows;
}

function isNewColumn(i: number, row: number, remaining: number) {
  return i > 0 && ((row === 0 && remaining >= 0) || (row === -1 && remaining < 0));
}
