import React from 'react';
import MediaQuery from 'react-responsive';
import { Grid } from '../Grid';
import { GridCell } from '../GridCell';
import { Container } from './Container';

export const NarrowContainer = ({ children, ...props }: any) => {
  return (
    <Container {...props}>
      <MediaQuery maxWidth={1023}>{children}</MediaQuery>

      <MediaQuery minWidth={1024}>
        <Grid className="ma0 pa0 gga12">
          <SpacerColumn />

          <GridCell className="ph6 ph12" width={[1, 1, 1, 10 / 12]}>
            {children}
          </GridCell>

          <SpacerColumn />
        </Grid>
      </MediaQuery>
    </Container>
  );
};

const SpacerColumn = () => <GridCell className="ph6 ph12" width={[0, 0, 0, 1 / 12]} />;
