import React from 'react';
import { NarrowContainer } from '../../components/Container/NarrowContainer';
import { Breadcrumb } from './components/Breadcrumb';
import { CategorizedLinkList } from './components/CategorizedLinkList';
import { Header } from './components/Header';
import { Topics } from './components/Topics';
import { Hyperlink, HyperlinkIcon } from './types';

const breadcrumbDataSource: Hyperlink[] = [
  {
    to: '/',
    text: 'Início',
  },
  {
    to: '/',
    text: 'Ajuda',
  },
  {
    to: '/',
    text: 'Vendas',
  },
  {
    to: '/',
    text: 'Anúncio',
  },
];

const topicsDataSource: HyperlinkIcon[] = [
  {
    iconName: 'SvgIconSales',
    to: '/',
    text: 'Vendas',
  },
  {
    iconName: 'SvgIconBuy2',
    to: '/',
    text: 'Compras',
  },
  {
    iconName: 'SvgIconAccount',
    to: '/',
    text: 'Conta',
  },
  {
    iconName: 'SvgIconSecurity',
    to: '/',
    text: 'Segurança',
  },
];

const doubtsDataSource: Hyperlink[] = [
  {
    to: '/',
    text: 'Como vender um carro?',
  },
  {
    to: '/',
    text: 'Tipos de anúncios',
  },
  {
    to: '/',
    text: 'Tipos de anúncios',
  },
  {
    to: '/',
    text: 'Dicas para tirar fotos',
  },
  {
    to: '/',
    text: 'Como anunciar um veículo?',
  },
  {
    to: '/',
    text: 'Como anunciar um veículo?',
  },
];

export const Faq = () => {
  return (
    <>
      <NarrowContainer semantic={false}>
        <Breadcrumb dataSource={breadcrumbDataSource} />
        <Header iconName="SvgIconSupport" title="COMO PODEMOS TE AJUDAR?" />
      </NarrowContainer>

      <Topics dataSource={topicsDataSource} />

      <NarrowContainer className="pv32 pt48-m pt48-l">
        <div className="mb24 mb40-m mb40-l">
          <CategorizedLinkList dataSource={doubtsDataSource} />
        </div>
      </NarrowContainer>
    </>
  );
};
