import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Icon } from '../../../../components/Icons';
import { HyperlinkIcon } from '../../types';
import styles from './style.module.css';

interface ImageBoxLinkProps {
  hyperlink: HyperlinkIcon;
}

export const ImageBoxLink = ({ hyperlink }: ImageBoxLinkProps) => {
  const { to, iconName, text } = hyperlink;

  const wrapperClasses = classNames(
    'bg-white br3 db f16 f24-m f24-l fw7 abbey pa12 pa16-m pa16-l tc no-underline',
    styles.wrapperCard,
  );

  return (
    <Link to={to} className={wrapperClasses}>
      <span className="db mb8 mb16-m mb16-l tl">{text}</span>
      <Icon className={styles.icon} name={iconName} />
    </Link>
  );
};
