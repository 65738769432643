import React from 'react';
import _map from 'lodash/map';
import { DefaultLink } from '../../../../components/Link/Default';
import { Hyperlink } from '../../types';

interface Props {
  dataSource: Hyperlink[];
}

export const ExpandableLinkList = ({ dataSource }: Props) => {
  const renderLink = ({ to, text }: Hyperlink) => (
    <li key={text} className="mb24">
      <DefaultLink to={to} className="abbey fw4 f14 dib">
        {text}
      </DefaultLink>
    </li>
  );

  return (
    <ul className="ma0 pa0 list abbey overflow-hidden">
      {_map(dataSource, (hyperlink: Hyperlink) => renderLink(hyperlink))}
    </ul>
  );
};
